<template>
  <div class="coursesSpeaking">
    <router-view v-if="dialoguesData.length" :coursesDialogueInfo="coursesDialogueInfo" :dialoguesData="dialoguesData" :audio="audio"></router-view>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { getLessonData } from '@/api/courses'
  import constants from '@/utils/constants'
  import Audio from '@/utils/audioUtil'

  export default {
    data() {
      return {
        dialoguesData: [],
        audio: null
      }
    },
    computed: {
      ...Vuex.mapGetters(['coursesDialogueInfo', 'userLanguage', 'learnLanguage']),
    },
    created() {
      this.initDialogue()
    },
    methods: {
      async initDialogue() {
        if (this.coursesDialogueInfo) {
          let loading = this.$loading()

          let dialogueData = await getLessonData(this.userLanguage, this.learnLanguage, this.coursesDialogueInfo.id)
          this.dialoguesData = this.handleDialoguesData(dialogueData)
          this.loadAudios()

          loading.close()
        } else {
          this.$router.push('/courses')
        }
      },
      loadAudios() {
        let dialoguesData = this.dialoguesData
        let audios = []
        for (let dialogue of dialoguesData) {
          let audioUrl =
            constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
            'lesson_f/' +
            constants.langsData[this.learnLanguage]['courseMediaName'] +
            `-f-s-${dialogue.id}.mp3`
          audios.push({ audioUrl })
        }
        this.audio = new Audio(audios)
      },
      handleDialoguesData(dialogueData) {
        let dialoguesData = []
        let { dialogRegex, list } = dialogueData
        let regexArr = dialogRegex.split('#')
        for (let regex of regexArr) {
          let regexStr = regex.split(';')[0]
          regexStr = '1' + regexStr.slice(1)
          let dialogueObj = list[regexStr]
          dialogueObj.type = this.getType(dialogueObj.wls[0].wd)
          dialogueObj.st = this.getSentence(dialogueObj.st).trim()
          dialogueObj.tr = this.getSentence(dialogueObj.tr).trim()
          dialoguesData.push(dialogueObj)
        }

        return dialoguesData
      },
      getType(str) {
        if (['M:', 'H:', 'H : '].includes(str)) {
          return 'male'
        } else if (['F:', 'F : ', 'F1:', 'F&M:'].includes(str)) {
          return 'female'
        } else {
          return 'aside'
        }
      },
      getSentence(str) {
        constants.coursesDialoguePrefix.forEach(item => str = str.replace(item, ''))
        return str
      }
    }
  }
</script>

<style lang="scss" scoped>
  .coursesSpeaking {
    position: relative;
    background: #F3F4F8;
  }
</style>